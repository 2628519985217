.file-container {
    height: 400px;
    cursor: pointer;
    background-color: #F5F5F5;
    border-radius: 1rem;
    overflow: hidden;
    padding: 5px;

    &.square {
        width: 400px;
        height: 400px;

        .dropzone {
            display: flex;
            align-items: center;
        }
    }

    .image-placeholder {
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.4;
        border-radius: 0.5rem;
        text-align: center;
        padding: 10px;
        margin: 0;
    }

    img.preview {
        height: 400px;
        width: 100%;
        margin: auto;
        display: flex;
        object-fit: cover;
        border-radius: 12px;
    }
}