.bridge-box {
    width: 100% !important;

    background-image: url(../../assets/images/swap_bg_1.png);
    background-position-x: center;
    background-repeat: no-repeat;
    padding-top: 160px;
    padding-left: 50px;
    padding-right: 50px;
    .box-content {
        border-radius: 14.656px;
        background: var(--colors-alias-black-white-white, #FFF);
        box-shadow: 0px 4.885px 4.885px 0px rgba(0, 0, 0, 0.25);
        padding: 20px 40px;
        .desc-item{   
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: fit-content;
            width: 370px;
            border-radius: 36px;
            padding:10px 32px;
            background: var(--colors-alias-black-white-white, #FFF);
            box-shadow: 0px 4.885px 4.885px 0px rgba(0, 0, 0, 0.23);                  
            .title{
                font-family: Poppins;  
                color: var(--Neutrals-2, #23262F);
                text-align: center;
                font-size: 22px;
                font-weight: 600;
            }
            .desc{
                color: #4C5266;
                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
    .accordion-container-new {
        background: transparent;
        margin-bottom: 20px;
        box-shadow: none;

        .MuiAccordion-root{
            border-top: 4px solid black;
            box-shadow: none;
            border-top-left-radius: 0px !important;
            border-top-right-radius: 0px !important;
        }
        .MuiAccordion-root.Mui-expanded{
            border-top: 4px solid #999;
        }
    }
}



.switcher {
    // width: 40px;
    // height: 40px;
    // border-radius: 50%;
    // border: 1px solid #4162B7;
    // background: #1A133D;
    // padding: 7px;
    position: absolute;
    left: 50%;
    top: calc(50%);
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;

    // &:hover {
    //     background: #352775;
    // }
}

.bridge-box .coming-text {
    font-weight: 300 !important;
    text-align: left;
    font-size: 72px;
    color: white;
}

.bridge-box .soon-text {
    font-weight: 600 !important;
    text-align: left;
    font-size: 72px;
    color: white;
}

.bridge-box .content {
    justify-content: space-between !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (max-width: 768px) {
    .bridge-box {
        width: 100% !important;
        height: 100vh;
        padding-top: 80px;
    }
    
    .bridge-box .coming-text {
        text-align: center;
        font-size: 30px;
    }
    
    .bridge-box .soon-text {
        text-align: center;
        font-size: 30px;
        margin-bottom: 35px;
    }
    
    .bridge-box .content {
        display: block !important;
    }
    
}