body * {
    font-family: "montserrat" !important;

    .outline-text {
        text-align: center;
        font-size: min(4.5vw, 75px);
        font-weight: 800;
        text-transform: uppercase;
        color: transparent !important;
        -webkit-text-stroke: 1px #2B1C39;
        margin: 100px 0;
    }

    .empty-text {
        margin: 100px 0;
        color: #000;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 90.909% */
        letter-spacing: -0.309px;
    }

    a {
        text-decoration: none;
        color: inherit;
    }
}

.MuiContainer-root {
    max-width: 1920px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;


}

.MuiSkeleton-rectangular {
    border-radius: 6px;
}

.MuiButtonBase-root.MuiPaginationItem-root {
    border-radius: 8.667px;
    border: 2.17px solid #EEE;
    min-width: 40px;
    min-height: 40px;
    color: #000;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    line-height: 1;
    margin: 0 6px;

    &.Mui-selected {
        color: #fff;
        font-weight: 600;
        background: #000;
        border: none;
    }

    &.MuiPaginationItem-previousNext {
        border: none;

        svg {
            font-size: 30px;
        }
    }
}

.MuiPaginationItem-root.MuiPaginationItem-ellipsis {
    color: white;
    border: none;
}

.MuiInputLabel-root {
    color: #000000 !important;
}
.MuiTextField-root{
    width: 100% !important;
}
.MuiInputBase-root.MuiOutlinedInput-root {
    min-width: min(80vw, 576px);
    width: 100%;
    background: #F5F5F5;
    // border-radius: 12px;

    .small & {
        min-width: 0;
    }

    input,
    textarea {
        color: black !important;
        font-weight: 600;
    }

    .MuiOutlinedInput-notchedOutline {
        border: none;
        transition: 0.3s all ease-in-out;
    }

    &:hover,
    &:focus,
    &:active {
        .MuiOutlinedInput-notchedOutline {
            // border-color: #000000 !important;
        }
    }

    &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
            // border-color: #000000 !important;
        }
    }
}

.input-label {
    color: #C9F;
    font-size: 16px;
    font-weight: 600 !important;
    line-height: 45px;
    padding-bottom: 10px !important;
}

.MuiFormHelperText-contained.MuiFormHelperText-root {
    color: #FFC700;
    font-size: 14px;
    font-weight: 600;
}

.MuiInputAdornment-root {
    svg {
        fill: #000000;
    }
}

.pink-icon {
    color: #000000;
}

.MuiSkeleton-root {
    background-color: #d49fff40 !important;
}

.w-100 {
    width: 100%;
}

.m-auto {
    margin: auto;
}
            
.MuiTooltip-tooltip {
    font-weight: 400 !important;
    background-color: #000 !important;
    padding: 8px 10px !important;
}

::-webkit-scrollbar {
    width: 10px;
    height: 3px; // Add this line to make the scrollbar thin for horizontal scrollbar
}

::-webkit-scrollbar-thumb {
    background-color: #FFFFFF;
    border-radius: 16px;
}

::-webkit-scrollbar-track {
    background: #D9D9D9 ;
}