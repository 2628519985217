.selectpair-container {
    width: 100%;
    color: white;
    padding: 13px 21px;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    margin-bottom: 10px;

    .label {
        font-size: 14px;
        padding-bottom: 10px;
    }

    .token-info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .token {
            display: flex;
            align-items: center;
            gap: 8px;

            .token-icon {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                background: #ffffff;
            }

            .symbol {
                font-size: 14px;
                font-weight: 700;
            }

            .pair-token {
                display: flex;
                align-items: center;
                justify-content: center;

                & :nth-child(2) {
                    margin-left: -20px;
                }

                .token-icon {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
}