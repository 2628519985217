.home-box {
    width: 100% !important;
    background-image: url(../../assets/images/swap_bg_1.png);
    background-position-x: center;
    background-repeat: no-repeat;
    padding-top: 110px;

    .banner-text {
        display: none !important;
    }

    .explore-title {
        margin-top: 460px;
    }

    .apply-btn {
        margin-top: 70px;
    }

    .showall-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 80px;
        margin-bottom: 20px;

        .showall-link {
            margin-right: 10px;
            cursor: pointer;
        }
    }

    .tabs-explore {
        margin-top: 60px;
    }

    .subtitle {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .card-title {
        color: #9A93A7;
        text-align: center;
        font-size: 48px;
        font-weight: 700;
        line-height: normal;
        padding-bottom: 20px;
    }

    .card-text {
        color: #9A93A7;
        text-align: center;
        font-size: 24px;
        padding: 0 10px;
    }

    .accordion-container-new {
        background: transparent;
        margin-bottom: 20px;
        box-shadow: none;

        .MuiAccordion-root{
            border-top: 4px solid black;
            box-shadow: none;
            border-top-left-radius: 0px !important;
            border-top-right-radius: 0px !important;
        }
        .MuiAccordion-root.Mui-expanded{
            border-top: 4px solid #999;
        }
    }

    .social {
        margin-top: 300px !important;
        margin-bottom: 50px;
    }

    .links .social-icon {
        padding: 0 30px;

        img {
            width: 45px !important;
        }
    }
    .home-digram-container{
        padding: 0px 80px;
        .desc-box{
            width: 370px;
            border-radius: 36px;
            padding: 10px 20px;
            background: var(--colors-alias-black-white-white, #FFF);
            box-shadow: 0px 64px 64px 20px rgba(0, 0, 0, 0.03);
            .label {
                color: var(--Neutrals-2, #23262F);
                text-align: center;
                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px; /* 130% */
            }
        }
    }
    
}

@media (max-width: 800px) {
    .home-box {
        width: 100vw !important;
        background-size: auto 580px;
        padding-top: 80px;

        .explore-title {
            margin-top: 200px;
        }

        .apply-btn {
            margin-top: 40px;
        }

        .showall-box {
            margin-top: 20px;
            margin-bottom: 5px;

            .arrow-right {
                width: 15px;
            }
        }

        .tabs-explore {
            margin-top: 10px;
        }

        .subtitle {
            margin-bottom: 50px;
        }

        .card-title {
            font-size: 20px;
            padding-bottom: 10px;
        }

        .card-text {
            font-size: 14px;
            margin-bottom: 50px;
        }

        .accordion-container-new {
            margin-bottom: 10px;
            .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation1 .MuiAccordion-root .MuiAccordion-rounded .MuiAccordion-gutters {
                border-top: 4px solid black;
            }
            .MuiAccordionSummary-root {
                font-size: 16px;
                padding: 0 15px;
                min-height: 50px;
                border-top: 4px solid black;
                background: transparent !important;
                // border: 4px solid black;
                
            }

            .MuiAccordion-root{
                border-top: 4px solid black;
            }

            .MuiAccordionDetails-root {
                font-size: 14px;
            }
        }

        .social {
            margin-top: 150px !important;
            margin-bottom: 30px;
        }

        .links .social-icon {
            padding: 0 10px;

            img {
                width: 25px !important;
            }
        }
    }
}