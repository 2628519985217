.inputcard-container {
    width: 100%;
    background: rgba(241, 241, 241, 0.75);
    cursor: pointer;
    user-select: none;
    margin-top: 20px;
    padding: 20px;

    .token-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding: 13px 21px;
        border-radius: 8px;
        // border: 1px solid #4162B7;

        height: 77px;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 5px;
            height: auto;
            padding: 15px 21px;
        }

        .token {
            padding-right: 20px;
            display: flex;
            align-items: center;
            height: 50px;

            .token-pair {
                display: flex;
                align-items: center;
                justify-content: center;

                .token-icon {
                    width: 35px;
                    height: 35px;
                    background: #261C54;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        .symbol {
            width: 70px;
            font-size: 14px;
            font-weight: 700;
            margin-left: 10px;
            display: flex;
        }

        .btn-max {
            padding: 1px 5px;
            font-size: 10px;
            font-weight: 500;
            color: #3a9aff;
            border: 1px solid #3a9aff;
            border-radius: 4px;
            transition: 0.2s all ease-in-out;
            margin-right: 8px;

            &:hover {
                background-color: #4162B7;
            }
        }

        .balance {
            color: #1B315D;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Montserrat;
            font-size: 14.656px;
            font-style: normal;
            font-weight: 400;
            line-height: 20.898px; /* 142.593% */
            letter-spacing: -0.251px;
            width: fit-content;
        }
        .max{
            color: #1B315D;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Montserrat;
            font-size: 14.656px;
            font-style: normal;
            font-weight: 700;
            line-height: 20.898px; /* 142.593% */
            letter-spacing: -0.251px;
        }

        .price {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            flex-direction: column;
            width: 300px;

            @media screen and (max-width: 768px) {
                width: 100%;
                flex-direction: row-reverse;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
            }

            .input-price {
                color: #1B315D;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Montserrat;
                font-size: 24.426px !important;
                font-style: normal;
                font-weight: 700;
                line-height: 20.898px; /* 85.556% */
                letter-spacing: -0.251px;
                &.disabled {
                    color: #6d6d6a;
                    height: 44.11px;
                    padding-top: 10px;
                }
            }

            .MuiInput-underline:before,
            .MuiInput-underline:after {
                border: none;
            }

            input {
                text-align: right;
                // font-size: 18px;
            }

            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type="number"] {
                -moz-appearance: textfield;
            }

            .price-label {
                color: #1B315D;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Montserrat;
                font-size: 14.656px;
                font-style: normal;
                font-weight: 700;
                line-height: 20.898px; /* 142.593% */
                letter-spacing: -0.251px;
                margin-top: -5px;

                &.disabled {
                    font-weight: 400;
                }
            }
        }
    }
}