.imagebtn-container {
    border-radius: 10px;
    padding: 6px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    .btn-wrapper {
        min-width: 140px;
        min-height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: #0030CF;
        // box-shadow: 0px 2px 0px 0px #6871E2, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset;
    }

    // &:hover {
    //     box-shadow: 0px 0px 120px 10px #2035F2;
    // }
}

.imagebtn-container-crossmark {
    // background: #FA4A28;
    // padding: 6px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out; 

    .btn-wrapper-crossmark {
        min-width: 140px;
        min-height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background:#F7393E;
    }

    // &:hover {
    //     box-shadow: 0px 0px 120px 10px #2035F2;
    // }
}