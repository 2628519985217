.create-box {
    width: 100% !important;
    background-image: url(../../assets/images/swap_bg_1.png);
    background-position-x: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding-top: 160px;
    padding-bottom: 60px;

    .switcher-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        border-bottom: 1px solid rgba(65, 98, 183, 0.5);
        padding-bottom: 12px;
        position: relative;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 5px;
        }
    }

    .switcher {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #4162B7;
        background: #1A133D;
        padding: 7px;
        position: absolute;
        left: 50%;
        top: calc(50%);
        transform: translate(-50%, -50%);
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;

        &:hover {
            background: #352775;
        }
    }

    .container-box {
        // .box-content {
        //     padding-top: 130px;
        // }

        .tabs {
            width: 100%;
            position: absolute;
            top: 80px;
            // padding-right: 66px;

            .MuiTabs-flexContainer {
                align-items: center;
                justify-content: center;
            }

            button {
                z-index: 100;
                width: calc(50% - 100px);
                color: black;
                text-transform: none;
                font-weight: 700;
                font-size: 32px;
                border-radius: 14.656px 0px 0px 14.656px;;
                background: white;
                border: 1px solid black;
                padding:0px;
                &.Mui-selected {
                    background: #DEDEDE;
                }
            }
            button.right {
                border-radius: 0px  14.656px  14.656px 0px ;
            }

            .MuiTabs-indicator {
                display: none;
            }
        }
    }

    .fee-config{
        background: rgba(241, 241, 241, 0.75);
        display: flex;
        align-items: center;
        margin-top: 20px;
        padding: 0px 20px;
        .fee-desc {
            color: #000;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 20.898px; /* 87.075% */
            letter-spacing: -0.251px;
            width: 230px;
        }

        .fee-input {
            width: 100%;
            outline: none;
            height: 40px;
            border-radius: 14.656px;
            background: transparent;
            color: #1B315D;
            font-weight: 700;
            font-size: 18px;
            text-align: right;
        }
    }
    .summary {
        padding-top: 20px;
        display: block;

        .summary-item {
            margin: 0 30px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: none;
            transition: 0.3s all ease-in-out;
            height: 0px;
            overflow: hidden;

            &.h-100 {
                height: 40px;
                border-bottom: 1px dashed #4c3e75;
            }

            @media screen and (max-width: 768px) {
                & {
                    flex-direction: column;
                    align-items: flex-start;
                    margin: 0;

                    &.h-100 {
                        height: fit-content;
                        padding: 5px 0;
                    }

                    & p {
                        width: 100%;

                        &:nth-child(2) {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }

}

@media (max-width: 768px) {
    .create-box {
        width: 100% !important;
        padding-top: 80px;
    }

    .create-box .coming-text {
        text-align: center;
        font-size: 30px;
    }

    .create-box .soon-text {
        text-align: center;
        font-size: 30px;
        margin-bottom: 35px;
    }

    .create-box .content {
        display: block !important;
    }

}