.gradient-text {
    text-align: center;
    font-weight: 700 !important;
    background: linear-gradient(180deg, #FFF 0%, #D0DCF5 19.79%, #A8A3B2 70.21%, #1C0A41 100%, #1C0A41 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    filter: drop-shadow(0px -1px #ffffff90);
    line-height: normal !important;
}

@media (max-width: 800px) {
    .gradient-text {
        
        scale: 0.5;
    }
}