.leader-content {
  width: 100%;
  border-radius: 14.962px;
  background: var(--colors-alias-black-white-white, #FFF);
  box-shadow: 0px 4.987px 4.987px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 60px;
  // margin-top: 30px;
  max-width: 100%;
  padding: 0;
  font-weight: 700;
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.panel-btn {
  width: 100%;
  border-radius: 8px;
  background: #D4D4D4;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  color: #000;

  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
}

.panel-btn.selected {
  background: var(--colors-alias-black-white-white, #FFF);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  
}

.leader-header {
  padding: 1rem;
  display: flex;
  width: 90%;
  margin: auto;
}

.leader-body {
  padding-top: 10px;
  padding-bottom: 30px;
}

.leaderboard-table {
  background: transparent;
  border-spacing: 0;
  width: 90%;
  margin: auto;
}

.leaderboard-table thead {
  color: #000;
}

.leaderboard-table td{
  text-align: right;
  color: #000;
  height: 50px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700 !important;
  }

.leaderboard-table th {
  height: 10px;
  text-align: right;
  padding: 16px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.leaderboard-table tr:nth-child(even){    background-color: rgba(121, 135, 173, .031);}

.leaderboard-table tr:hover {
  cursor: pointer;
  background-color: #3e6ff740;
  transition: all .2s ease-in-out;
}

.trade-btn{
  background: #000 !important;
  border-radius: 8px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 2px 15px;
  cursor: pointer;
}

// .leaderboard-table th {
//     padding-top: 12px;
//     padding-bottom: 12px;
//     text-align: left;
//     background-color: #04AA6D;
//     color: white;
// }

ul.react-paginate {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  color: #000;
  gap:10px;

}

ul.react-paginate li a {
  border-radius: 7px;
  border: none;
  padding: 0.1rem 1rem;
  cursor: pointer;
  border-radius: 8.667px;
  border: 2.17px solid #EEE;
  width: 52px;
  height: 52px;
}
ul.react-paginate li.previous a,
ul.react-paginate li.next a,
ul.react-paginate li.break a {
  // border-color: transparent;
}
ul.react-paginate li.selected a {

  color: white;
  min-width: 32px;
  border-radius: 8.667px;
  border: 2.167px solid #000;
  background: #000;
}
ul.react-paginate li.disabled a {
  color: grey;
}
ul.react-paginate li.disable,
ul.react-paginate li.disabled a {
  cursor: default;
}

// .bg {
//   color:#000;
//   -webkit-backdrop-filter: blur(5px) !important;
//   backdrop-filter: blur(5px) !important;
//   background: radial-gradient(107.88% 158.34% at -73.69% 109.35%, rgba(51, 33, 167, .4) 0, rgba(24, 16, 45, .4) 100%) !important;
// }

.custom-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #000;
  font-size: 20px;
  padding: 10px 5px;
  font-family: "montserrat" !important;
  font-weight: 700;
  border-radius: 8px;
  border: 1px solid var(--border, #E8EFFB);
  background: var(--bg-light-100, #FAFCFE);
}

.custom-select select option {
  color: var(--Dark, #141A21);
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.pair-token {
  display: flex;
  align-items: center;
  justify-content: center;

  & :nth-child(2) {
      margin-left: -15px;
  }

  .token-icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
  }
}
.token-icon {
width: 32px;
height: 32px;
border-radius: 50%;
overflow: hidden;
}
// .pair-label {
//   color: #CEC3FF;
//   font-weight: 500;
//   font-size: 16px;
//   margin-left: 10px !important;
// }
