.faucet-box {
    width: 100% !important;
    min-height: 100vh;
    background-image: url(../../assets/images/comingsoon-bg.jpg);
    background-position-x: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 30px;
    padding-top: 160px;
    padding-bottom: 60px;
    color: #CEC3FF;

    @media screen and (max-width: 768px) {
        padding: 30px 10px;
        padding-top: 100px;
        padding-bottom: 30px;
    }

    .faucet-container {
        max-width: 500px !important;
        width: 100%;
        border-radius: 30px;
        border: 1px solid #4162B7;
        background: radial-gradient(107.88% 158.34% at -73.69% 109.35%, rgba(51, 33, 167, 0.40) 0%, rgba(24, 16, 45, 0.40) 100%);
        backdrop-filter: blur(5px);
        padding: 50px 0;

        & {
            @media (max-width: 768px) {
                padding: 30px 10px 0 10px;
            }
        }

        .no-data {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            font-size: 14px;
            font-weight: 500;
            color: #CEC3FF;
        }

        .title-box {
            padding: 0 5%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            & {
                @media (max-width: 768px) {
                    flex-direction: column;
                    padding: 0;
                }
            }

            .label {
                color: #CEC3FF;
                font-weight: 600;
                margin-bottom: 30px;
                text-transform: uppercase;
                margin-bottom: 0;

                & {
                    @media (max-width: 768px) {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .token-issuer {
            color: #CEC3FF;
            font-size: 12px;
        }

        .scroll-box {
            overflow-x: auto;
            padding-bottom: 20px;
            margin: 0 30px;

            @media screen and (max-width: 768px) {
                margin: 0 10px;
            }
        }

        .icon-box {
            background: transparent;
        }

        .token-icon {
            width: 100%;
            height: 100%;
        }

        .btn-faucet {
            border-radius: 33px;
            background: #A9B2FF !important;
            box-shadow: 0px 2px 0px 0px #6663FA, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset;
            color: #000;
            font-size: 14px;
            font-weight: 500;
            padding: 2px 10px;
        }
    }
}