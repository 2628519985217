.detail-box {
    width: 100% !important;
    background-image: url(../../assets/images/swap_bg_1.png);
    background-position-x: center;
    background-repeat: no-repeat;
    padding: 110px 50px;

    .banner-text {
        display: none !important;
    }

    .no-filter {
        filter: none;
    }

    .explore-title {
        margin-top: 160px;
    }

    .token-icon {
        width: 200px;
        height: 200px;
        display: block;
        margin: auto;
        margin-top: 60px;

        img {
            border-radius: 6px;
        }
    }

    .token-info {
        width: min(80vw, 750px);
        display: flex;
        justify-content: space-between;
        margin: auto;
        margin-top: 60px;

        .token-name>div {
            text-align: left !important;
        }
    }

    .status-label {
        color: white;
        font-size: 24px;
        line-height: normal;
        padding-top: 10px;
        margin-bottom: 14px;
        text-align: right;
    }

    .countdown {
        color: #FFF;
        font-size: 60px;
        font-weight: 100;
        line-height: normal;
        text-align: right;
    }

    .progressbar {
        width: min(80vw, 870px);
        margin: auto;
        margin-top: 60px;
        position: relative;

        .MuiLinearProgress-root {
            height: 6px;
            border-radius: 15px;

            span {
                border-radius: 15px;
            }
        }

        .MuiLinearProgress-dashed {
            background: #211736;
            animation: none;
        }

        .MuiLinearProgress-bar1Buffer {
            background: linear-gradient(270deg, #D196FF 0%, #7B90FE 100%);
            border-radius: 15px;
        }

        .MuiLinearProgress-bar2Buffer {
            background-color: #312649;
        }

        .total-raised {
            color: white;
            font-size: 30px;
            font-weight: 400;
        }

        .cap-label {
            color: white;
            text-align: center;
            font-size: 24px;
            font-weight: 400;
            line-height: 1.2;
            position: absolute;
            top: 60px;
            text-align: right;
        }
    }

    .buy-form {
        display: flex;
        align-items: center;
        justify-content: center;
        width: min(80vw, 600px);
        margin: auto;
        margin-top: 160px;
        gap: 50px;
    }

    .policy-form {
        display: flex;
        align-items: center;
        justify-content: center;
        width: min(80vw, 600px);
        margin: auto;
        margin-top: 160px;
        gap: 50px;
    }

    .social {
        margin-top: 300px !important;
        margin-bottom: 50px;
    }

    .summary {
        width: min(80vw, 900px);
        color: #9A93A7;
        font-size: 24px;
        line-height: 150%;
        margin: auto;
        margin-top: 30px;

        p {
            margin: 0 !important;
        }
    }

    .video {
        text-align: center;
        margin: auto;
        margin-top: 50px;
        width: min(80vw, 900px) !important;
        height: 500px !important;
        border-radius: 20px;

        iframe {
            width: 100%;
            height: 100%;
            border-radius: 20px;
        }
    }

    .chart {
        width: min(80vw, 400px);
        margin: auto;
        margin-top: 50px;
        position: relative;

        .chart-center-text {
            position: absolute;
            color: black;
            top: 50%;
            left: 50%;
            font-size: 20px;
            transform: translateX(-50%) translateY(-50%);
            text-align: center;

            strong {
                font-weight: 700;
                font-size: 30px;
            }
        }
    }

    .datalabels {
        color: white;
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;

        .color-label {
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }
    }

    .links .social-icon {
        padding: 0 30px;

        img {
            width: 45px !important;
        }
    }
    
    .project-social-icon {
        padding: 0;
        margin-top: 30px;
        margin-left: 10px;
        margin-right: 10px;
        z-index: 1;

        img {
            width: 25px;
        }
    }

    .content-panel{
        border-radius: 14.656px;
        background: var(--colors-alias-black-white-white, #FFF);
        box-shadow: 0px 4.885px 4.885px 0px rgba(0, 0, 0, 0.25);
        padding: 20px 40px;
        .prj-name{
            color: #1E7827;
            text-align: center;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Montserrat;
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            // line-height: 20.898px; /* 32.653% */
            letter-spacing: -0.251px;
        }
        .prj-desc{
            .title{
                color: #000;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Montserrat;
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px; /* 125% */
                letter-spacing: -0.251px;
            }
            .content {
                color: #000;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Montserrat;
                font-size: 40px;
                font-style: normal;
                font-weight: 500;
                line-height: 50px; /* 125% */
                letter-spacing: -0.251px; 
            }
        }
        .detail-panel{
            background: #F3F3F3;
            border-radius: 20px;
            padding: 40px;

            .token-icon{
                margin: 0px;
                width: 150px;
                height: 150px;
            }
            .token-lbl{
                color: #2AAB3E;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Montserrat;
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px; /* 125% */
                letter-spacing: -0.251px;
            }
            .time-panel{
                display: flex;
                margin-top: 30px;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 51.076px;
                background: var(--colors-alias-black-white-white, #FFF);
                text-align: center;
                .countdown{
                    color: #000;
                    font-size: 66.261px;
                    font-style: normal;
                    font-weight: 700;
                    // line-height: 28.848px; /* 43.537% */
                    letter-spacing: -0.346px;
                }
                .sub-desc{
                    font-size: 27.609px;
                    font-style: normal;
                    font-weight: 600;
                }
            }
            .raised-panel{
                margin-top: 30px;
                .lbl-amount{
                    color: #000;
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-family: Montserrat;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40px;
                    letter-spacing: -0.251px;
                }
            }
            .lbl-terms{
                margin-top: 30px;
                text-align: center;
                font-weight: 600;
                font-size: 24px;
            }

            .buy-form{
                padding: 0px 40px;
                display: flex;
                width: 100%;
                gap:0px;
                margin-top: 30px;
                font-size: 40px;
                font-weight: 700;
                input{
                    border-radius: 24.426px 0px 0px 24.426px;
                    width: 100%;
                    text-align: center;
                    color: black;
                    padding: 5px 0px;
                    
                }
                button{
                    padding: 5px 0px;
                    border-radius: 0px 24.426px 24.426px 0px;
                    background: #000;
                    color: white;
                    width: 100%;
                }
            }

            .contribution-panel{
                margin-top: 30px;
                .title {
                    text-align: center;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 700;
                }

                
            }
        }
    }
    .MuiLinearProgress-bar1Buffer	{
        background-color: #26B519;
        border-radius: 25.01px;
    }
    .MuiLinearProgress-bar2Buffer{
        background: #B9B0B0;
    }
    .MuiLinearProgress-dashed	{
        background-image: none;
        animation: none;
 
    }
    .MuiLinearProgress-root {
        height: 16px;
        background: #B9B0B0;
        border-radius: 25.01px;
    }
    .MuiAccordion-root{
        border-top: 4px solid black;
        box-shadow: none;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    .MuiAccordion-root.Mui-expanded{
        border-top: 4px solid #999;
    }
}

@media (max-width: 800px) {
    .detail-box {
        width: 100vw !important;
        background-size: auto 420px;
        padding-top: 80px;

        .tabs-explore {
            margin-top: 10px;
        }

        .explore-title {
            margin-top: 50px;
        }

        .token-icon {
            width: 100px;
            height: 100px;
            margin-top: 30px;
        }

        .chart {
            .chart-center-text {
                font-size: 14px;
               
                strong {
                    font-size: 20px;
                }
            }
        }

        .datalabels {
            flex-direction: column;
            gap: 10px;
        }

        .status-label {
            font-size: 20px;
            font-weight: 400;
        }

        .countdown {
            font-size: 30px;
            text-align: center;
            padding: 0 !important;
        }

        .token-icon {
            margin-top: 30px;
        }

        .token-info {
            flex-direction: column;
            margin-top: 30px;

            .token-name>div {
                text-align: center !important;
            }
        }

        .status-label {
            font-size: 16px;
            margin-top: 20px;
            text-align: center;
            margin-bottom: 5px;
        }

        .summary {
            font-size: 14px;
            margin-top: 15px;
        }

        .countdown {
            margin: auto;
            text-align: center;
            padding-left: calc(50% - 80px);
        }

        .progressbar {
            margin-top: 30px;

            .total-raised {
                font-size: 14px;
            }

            .cap-label {
                font-size: 12px;
                top: 35px;
            }
        }

        .buy-form {
            flex-direction: column;
            gap: 20px;
            margin-top: 80px;
        }

        .social {
            margin-top: 150px !important;
            margin-bottom: 30px;
        }

        .links .social-icon {
            padding: 0 10px;

            img {
                width: 25px !important;
            }
        }
    }
}