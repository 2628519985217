.all-liquidity-box {
    width: 100% !important;
    background-image: url(../../assets/images/swap_bg_1.png);
    background-position-x: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding-top: 160px;
    padding-left: 60px !important;
    padding-right: 60px !important;
    .table-container {
        margin-top: 30px;
        margin-bottom: 60px;
        max-width: 100%;
        border-radius: 30px;
        border-radius: 14.656px;
        background: var(--colors-alias-black-white-white, #FFF);
        box-shadow: 0px 4.885px 4.885px 0px rgba(0, 0, 0, 0.25);
        padding: 50px 0;

        & {
            @media (max-width: 768px) {
                padding: 30px 10px 0 10px;
            }
        }

        .btn-liquidity {
            display: flex;
            padding: 2px 5px;
            justify-content: center;
            align-items: center;
            gap: 9.77px;
            flex-shrink: 0;
            border-radius: 7px;
            background: #000;
            color:white;
            color: var(--colors-alias-black-white-white, #FFF);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 20.898px; /* 65.306% */
            letter-spacing: -0.251px;
        }

        .tbl-liquidity {
            padding: 0 30px;

            table {
                border-spacing: 0;
                width: 90%;
                margin: auto;
            }

            td, th {
                padding: 0;
                text-align: left;
            }

            th{
                height: 0px;
            }

            tr {
                transition: 0.2s all ease-in-out;
                cursor: pointer;
                height: 50px;

                &:nth-child(even) {
                    background-color: #7987ad08;
                }
            }

            tr:hover {
                background-color: #3e6ff740,
            }
        }

        .title-box {
            padding: 0 5%;
            display: flex;
            align-items: center;
            justify-content: space-start;
            gap:10px;
            margin-bottom: 20px;

            & {
                @media (max-width: 768px) {
                    flex-direction: column;
                    padding: 0;
                }
            }

            .label {
                color: #000;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Montserrat;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                letter-spacing: -0.251px;

                & {
                    @media (max-width: 768px) {
                        margin-bottom: 20px;
                    }
                }
            }

            .action-box {
                display: flex;
                align-items: center;
                gap: 10px;

                @media (max-width: 768px) {
                    flex-direction: column;

                    button {
                        width: 100%;
                    }
                }

                .btn-addliquidity {
                    border-radius: 10px;
                    background: #ff8282 !important;
                    box-shadow: 0px 2px 0px 0px #c94e4e, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset;
                    color: #000;
                    font-size: 14px;
                    font-weight: 500;
                    padding: 7px 15px;
                }

                .btn-addliquidity:hover {
                    background: #6ecaff !important;
                    box-shadow: 0px 2px 0px 0px #3e6ff7, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset;
                }
            }

            .MuiInputBase-root.MuiOutlinedInput-root {
                width: 210px !important;
                min-width: 0;
                height: 40px;
                border-radius: 10px;
                background: #241B38;

                .small & {
                    min-width: 0;
                }

                input,
                textarea {
                    color: white !important;
                }

                .MuiOutlinedInput-notchedOutline {
                    border: none;
                    transition: 0.3s all ease-in-out;
                }

                &:hover,
                &:focus,
                &:active {
                    .MuiOutlinedInput-notchedOutline {
                        border: 1px solid #4162B7 !important;
                    }
                }

                &.Mui-focused {
                    .MuiOutlinedInput-notchedOutline {
                        border: 1px solid #4162B7 !important;
                    }
                }
            }
        }

        .scroll-box {
            overflow-x: auto;
            padding-bottom: 20px;
            margin: -53px 0px 0px 0px;

            @media screen and (max-width: 768px) {
                margin: 0 10px;
            }
            .no-table-note{
                margin:72px 0px 0px 0px;
                color: #000;

                text-align: center;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Montserrat;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 58px; /* 123.404% */
                letter-spacing: -0.251px;

                .sub-content{
                    font-weight: 500;
                    line-height: 0px;
                }
            }
        }

        .pair-token {
            display: flex;
            align-items: center;
            justify-content: center;

            & :nth-child(2) {
                margin-left: -15px;
            }

            .token-icon {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                overflow: hidden;
            }
        }

        .pair-label {
            color: #000;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20.898px; /* 65.306% */
            letter-spacing: -0.251px;
        }
        .sub-label {
            color: #000;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            // line-height: 20.898px; /* 130.612% */
            letter-spacing: -0.251px;
        }

        table {
            th {
                text-align: center;
                font-size: 14px;
                font-weight: 500;
            }

            td {
                text-align: center;
                color: #CEC3FF;
                font-weight: 400;
                font-size: 16px;
            }
        }

        .btn-manage {
            border-radius: 33px;
            background: #A9B2FF !important;
            box-shadow: 0px 2px 0px 0px #6663FA, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset;
            color: #000;
            font-size: 14px;
            font-weight: 500;
            padding: 2px 10px;
        }
    }
}

.liq-desc {
    background: rgba(232, 244, 252, 0.30);
    .title-explain{
        color: var(--Neutrals-2, #23262F);
        text-align: center;
        font-family: Poppins;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 76px; /* 158.333% */ 
    }
}