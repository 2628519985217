.containerbox-container {
    padding: 2px;
    margin-bottom: 40px;
    position: relative;
    margin: auto;
    color: black;
    width: 100%;
    max-width: 620px;

    .box-content {
        border-radius: 14.656px;
        background: var(--colors-alias-black-white-white, #FFF);
        box-shadow: 0px 4.885px 4.885px 0px rgba(0, 0, 0, 0.25);
    }

    .highlight-top {
        max-width: 100%;
        position: absolute;
        transform: translateY(-50%);
        top: 3px;
        left: 3%;
        z-index: 0;
    }

    .highlight-bottom {
        max-width: 100%;
        position: absolute;
        transform: translateY(50%);
        bottom: 0px;
        right: 4%;
        z-index: 0;
    }

    .highlight-left {
        width: 15px;
        max-height: 100%;
        position: absolute;
        transform: translateX(-50%);
        left: 0px;
        top: 0;
        z-index: 0;
    }

    .highlight-right {
        width: 15px;
        max-height: 100%;
        position: absolute;
        transform: translateX(35%);
        right: 0px;
        bottom: 0;
    }

    .box-header {
        text-align: center;
        // border-bottom: 1px solid rgba(65, 98, 183, 0.50);

        .title {
            color: #000;

            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Montserrat;
            font-size: 58.623px;
            font-style: normal;
            font-weight: 700;
            // line-height: 20.898px; /* 35.648% */
            letter-spacing: -0.251px;
        }

        .description {
            font-size: 16px;
            color: #ffffff70;
            padding-bottom: 25px;
        }
    }

    .box-body {
        padding-top: 20px;
        // padding-bottom: 20px;
    }

    .btn-submit {
        width: -webkit-fill-available;
        height: 90px;
        border-radius: 14.656px;
        background: #000;
        color: var(--colors-alias-black-white-white, #FFF);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 58.623px;
        font-style: normal;
        font-weight: 700;
        line-height: 20.898px; /* 35.648% */
        letter-spacing: -0.251px;
    }

    .btn-info {
        width: -webkit-fill-available;
        height: 90px;
        border-radius: 14.656px;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 35.648% */
        letter-spacing: -0.251px;
    }
}

@media (max-width: 600px) {
    .containerbox-container {
        width: 100%;
        max-width: none;
    }
}