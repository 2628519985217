.backdrop {
    background-color: #130B1B50;
    backdrop-filter: blur(5px);
    z-index: 9;
}

.modal-wrapper {
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 999;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;

    .success-text {
        color: #7EE14F;
        text-align: center;
        font-size: 30px;
        font-weight: 500;
    }

    .mobile-box {
        display: flex;
        align-items: center;
        justify-content: center;

        .token-details {
            border-left: 1px solid #4A3C80;
            padding-left: 50px;
        }

        .token-icon {
            width: 150px;
            height: 150px;
            padding: 50px;
            display: block;

            img {
                border-radius: 6px;
            }
        }

        .token-symbol {
            color: #FFF;
            font-size: 36px;
            font-weight: 700 !important;
            line-height: normal;
            text-transform: uppercase;
        }

        .token-name {
            color: #FFF;
            font-size: 24px;
            line-height: normal;
            text-transform: uppercase;
            margin-bottom: 24px;
        }

        .token-spec {
            color: #8A73AC;
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
        }

        .token-address {
            display: flex;
            align-items: center;
            gap: 10px;
            color: #8A73AC;
            font-size: 18px;
            font-weight: 600;
        }

        .copy-icon {
            cursor: pointer;
            transition: 0.2s all ease-in-out;
        }
        
        .copy-icon:hover {
            color: #fff;
        }
    }
}

@media (max-width: 800px) {
    .modal-wrapper {
        padding: 0px 20px;

        .success-text {
            font-size: 16px;
        }

        .mobile-box {
            flex-direction: column;
            text-align: center;

            .token-details {
                border-left: none;
                padding-left: 0;
            }

            .token-icon {
                width: 100px;
                height: 100px;
                padding: 30px;
            }

            .token-symbol {
                font-size: 28px;
            }
    
            .token-name {
                font-size: 20px;
            }

            .token-address {
                flex-direction: column;
                gap: 5px;
                margin-bottom: 15px;

                * {
                    font-size: 16px;
                    line-height: 1;
                    color: white;
                    font-weight: 400;
                }

                .copy-icon {
                    font-size: 30px;
                }
            }
        }
    }
}