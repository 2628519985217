.btn-container {
    width: fit-content;
    background: #3841C1;
    border-radius: 100px;
    padding: 6px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    .btn-wrapper {
        padding: 10px 18px;
        min-width: 100px;
        min-height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        background: linear-gradient(180deg, #3A42FC 0%, #7B90FE 100%);
        box-shadow: 0px 2px 0px 0px #6871E2, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset;
    }

    &:hover {
        box-shadow: 0px 0px 120px 10px #2035F2;
    }
}

@media (max-width: 800px) {
    .btn-container {
        .btn-wrapper {
            padding: 0px 10px;

            img {
                width: 20px;
            }
        }

        .btn-label {
            padding-left: 3px;
            font-size: 14px;
        }
    }
}