.colorbox-container {
    border-radius: 12px;
    background: linear-gradient(180deg, #D196FF 0%, #7B90FE 100%);
    padding: 2px;
    margin-bottom: 40px;

    .box-content {
        background: #211736;
        border-radius: 15px;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 0.3s all ease-in-out;
        position: relative;

        .hover-card {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: 0.3s all ease-in-out;
            z-index: 0;
        }

        &:hover {
            .hover-card {
                opacity: 1;
            }
        }

        .highlight-top {
            max-width: 100%;
            position: absolute;
            transform: translateY(-50%);
            top: 0;
            right: 3%;
        }

        .highlight-bottom {
            max-width: 100%;
            position: absolute;
            transform: translateY(50%);
            bottom: -2px;
            left: 4%;
        }

        .highlight-left {
            width: 15px;
            max-height: 100%;
            position: absolute;
            transform: translateX(-50%);
            left: 1px;
            top: 0;
        }

        .highlight-right {
            width: 15px;
            max-height: 100%;
            position: absolute;
            transform: translateX(50%);
            right: -3px;
            bottom: 0;
        }

        .token-icon {
            width: 150px;
            height: 150px;
            padding: 50px;
            display: flex;
            align-items: center;

            img {
                border-radius: 20px;
            }
        }

        .token-icon-mobile {
            display: none;

            img {
                border-radius: 6px;
            }
        }

        .details {
            width: calc(100% - 300px);
            height: 100%;
            min-height: 180px;
            border-left: 1px solid #4A3C80;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: white;
            padding-left: 50px;
            margin-top: 30px;
            margin-bottom: 30px;

            .token-detail {
                display: flex;
                align-items: center;
                min-width: 310px;
                padding-right: 50px;

                .token-symbol {
                    color: #FFF;
                    font-size: 36px;
                    font-weight: 700 !important;
                    line-height: normal;
                    text-transform: uppercase;
                }

                .status-icon {
                    width: 20px;
                    height: 20px;
                    margin-left: 10px;
                }

                .status-text {
                    color: #68FF4D;
                    font-size: 14px;
                    font-weight: 600 !important;
                    line-height: normal;
                    text-transform: uppercase;
                    margin-left: 5px;

                    &.failed {
                        color: #ff215d;
                    }
                }
            }

            .token-name {
                color: #FFF;
                font-size: 24px;
                line-height: normal;
                text-transform: uppercase;
                margin-bottom: 24px;
            }

            .token-spec {
                max-width: 310px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #8A73AC;
                font-size: 18px;
                line-height: normal;
                text-transform: uppercase;
                padding-right: 50px;

                p {
                    font-weight: 600 !important;
                }
            }
        }

        .status-details {
            min-width: 400px;

            .status-label {
                color: #8A73AC;
                font-size: 24px;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 6px;
            }

            .countdown {
                color: #FFF;
                font-size: 48px;
                font-weight: 100;
                line-height: normal;
                padding: 10px 0;
            }

            .social-icon {
                margin-top: 18px;
                padding: 0;
                padding-right: 20px;
                z-index: 1;

                img {
                    width: 25px;
                }
            }
        }
    }
}

.box-container{
    border-radius: 20px;
    border: 1px solid #000;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
    margin:30px 0px;
    .token-icon {
        width: 300px;
        height: 300px;
        // padding: 20px;
        display: flex;
        align-items: center;

        img {
            border-radius: 300px;
        }
    }
    .token-desc {
        color: #000;
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
    }
    .social-icon {
        margin-top: 18px;
        padding: 0;
        padding-right: 20px;
        z-index: 1;

        img {
            width: 25px;
        }
    }
    .lbl-time{
        color: #000;
        text-align: center;
        font-size: 77px;
        font-style: normal;
        font-weight: 700;
        line-height: 61.584px; /* 79.979% */
        letter-spacing: -0.309px;
    }
    .btn-view{
        border-radius: 15.877px;
        background: #030303;
        color: var(--colors-alias-black-white-white, #FFF);
        font-size: 69.677px;
        padding: 5px 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 72.581px; /* 104.167% */
        letter-spacing: -0.364px;
        width: fit-content;
    }

    .countdown{
        border-radius: 20.349px;
        border: 3px solid #000;
        
        background: #FFF; 
        color: #000;
        padding: 0px 10px;
        font-size: 61.521px;
        font-style: normal;
        font-weight: 700;
    }

}

@media (max-width: 1000px) {
    .box-content {
        .details {
            width: 100% !important;
            margin: 50px 0;
            display: block !important;

            .status-icon {
                margin-left: 0px;
            }
        }

        .status-details {
            margin-top: 50px;
        }
    }
}

@media (max-width: 680px) {
    .colorbox-container {
        width: 75vw;
        margin-left: auto;
        margin-right: auto;

        .box-content {
            display: block;

            .token-icon {
                display: none;
            }

            .token-icon-mobile {
                display: flex;
                align-items: center;
                width: 50px;
                height: 50px;
                margin-right: 20px;
            }

            .mobile-box {
                padding: 20px;
                display: flex;
                align-items: center;
            }

            .details {
                display: block;
                padding: 0;
                margin: auto;
                padding-bottom: 20px;
                border: none;

                .token-detail {
                    text-align: center;
                    padding-right: 0;
                    min-width: 0;

                    .token-icon-mobile {
                        display: block;
                    }

                    .token-symbol {
                        font-size: 20px;
                    }

                    .status-icon {
                        width: 12px;
                        height: 12px;
                    }

                    .status-text {
                        font-size: 12px;
                    }
                }

                .token-name {
                    font-size: 16px;
                    margin-bottom: 0;
                }

                .token-spec {
                    padding: 0 20px;
                    margin: auto;
                    max-width: 100%;

                    p {
                        font-size: 12px !important;
                    }
                }

                .status-details {
                    min-width: 0;
                    margin-top: 20px;
                    text-align: center;
                    border-top: 1px solid #4A3C80;
                    margin: 10px 20px;
                    padding-top: 20px;

                    .status-label {
                        font-size: 20px;
                        font-weight: 400;
                    }

                    .countdown {
                        font-size: 30px;
                    }

                    .social-icon {
                        padding-right: 15px;

                        img {
                            width: 20px !important;
                        }
                    }
                }
            }
        }
    }
}