.modal-wrapper {
    .modal-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: min(80vw, 500px);
        background-color: white;
        padding: 30px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .MuiSvgIcon-root.MuiStepIcon-root {
        &.Mui-active {
            color: #3052ff !important;
        }

        &.Mui-completed {
            color: #3052ff !important;
        }
    }

    .MuiStepLabel-label {
        line-height: 1;

        &.Mui-active {
            font-size: 16px;
        }
    }

    .MuiButton-root.MuiButton-contained {
        background-color: #3052ff !important;
    }

    .description {
        font-size: 14px;
    }

    .modal-detail {
        position: relative;
        background: white;
    }

    .modal-box {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
    }

    .loading-indicator {
        height: 20px !important;
        position: absolute;
        left: -2px;
        top: calc(100% - 8px);
    }
    .btn {
        padding: 10px 18px;
        min-width: 100px;
        min-height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        background: linear-gradient(180deg, #3A42FC 0%, #7B90FE 100%);
        box-shadow: 0px 2px 0px 0px #6871E2, 0px 1px 0px 0px rgba(255, 255, 255, 0.4) inset;
    }
}

@media (max-width: 800px) {
    .modal-wrapper {
        .modal-content {
            flex-direction: column;
        }

        .modal-box {
            flex-direction: column;
        }
    }
}