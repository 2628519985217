.token-modal.modal-content {
    // background-color: #0d0f1e;
    display: block;
    // border: 1px solid #394a79;
    border-radius: 30px;
    // box-shadow: 0px 0px 130px 30px black;
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
        border-radius: 0;
        box-shadow: none;
    }

    .btn-black{
        border-radius: 12px;
        background: #000;
        color: var(--colors-alias-black-white-white, #FFF);

        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        // line-height: 17.111px; /* 35.648% */
        letter-spacing: -0.205px;
    }

    .search-box {
        width: 100%;
        // gap: 10px;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 10px;

            button {
                width: 100%;
                margin-left: 0;
            }

            .actions-box {
                width: 100%;
                gap: 10px;
            }
        }
    }

    .actions-box {
        background-color: black;
        height: 40px;
        border-radius: 0px 17px 17px 0px;
        button {
            background-color: transparent;
            color: white;
            padding: 0px 8px;
        }
    }

    .label{
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 14.223px; /* 67.727% */
        letter-spacing: -0.171px;
    }

    .close-button {
        position: absolute;
        color: black;
        top: 20px;
        right: 20px;
    }

    .divider {
        border-radius: 777px;
        opacity: 0.7;
        background: #D9D9D9;
        backdrop-filter: blur(2px);
        margin-bottom: 10px;
        width: 100%;
        height: 5px;
        flex-shrink: 0;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .MuiInputBase-root.MuiOutlinedInput-root {
        min-width: 100%;
        height: 40px;
    }

    .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
    .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border: none !important;
    }

    .title {
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 39.897px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: -0.171px;
        text-align: center;
        margin-bottom: 10px;
    }

    .err {
        color: #F00;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        text-align: center;
        padding: 0px 0px 5px 0px;
        // line-height: 14.223px; /* 67.727% */
        letter-spacing: -0.171px;
    }

    .MuiInputAdornment-root svg {
        fill: white;
    }

    .search .MuiInputBase-root.MuiOutlinedInput-root{
        border-radius: 12px;
    }
    .MuiInputBase-root.MuiOutlinedInput-root {
        background: #FDFDFD;
        border: 1px solid black;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }

    .pinned-tokens {
        display: flex;
        flex-wrap: wrap;
        margin: 10px 0;
        max-height: 40vh;
        overflow-y: auto;
        padding-bottom: 10px;

        .token {
            margin: 5px;
            cursor: pointer;
            background-color: black;
            border-radius: 10px;
            padding: 8px 10px;
            color: white;
            font-size: 16px;
            transition: all 0.2s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            &:hover {
                background-color: #323e65;
            }

            img {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                background-color: transparent;
            }
        }
    }

    .token-list {
        padding: 20px 0;
        max-height: 40vh;
        overflow-y: auto;

        .token-item {
            border-radius: 14.656px;
            background: #FEFEFE;
            box-shadow: 0px 4.885px 4.885px 0px rgba(0, 0, 0, 0.25);
            color: black;
            cursor: pointer;
            border-radius: 10px;
            transition: 0.2s all ease-in-out;
            margin: 16px 6px;
            width: 415px;
            &:hover {
                background-color: #323e6540;
            }
        }

        .token-issuer p {
            color: black;
        }

        .MuiAvatar-root {
            background-color: transparent;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .error {
        text-align: center;
        color: #4d62a8;
        min-height: 300px;
        .error-title {
            color: #000;
            text-align: center;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Montserrat;
            font-size: 21px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 95.238% */
            letter-spacing: -0.171px;
        }

        .error-desc {
            font-size: 14px;
        }
    }
}