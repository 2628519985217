.liquidity-box {
    width: 100% !important;
    min-height: 100vh;
    background-image: url(../../assets/images/comingsoon-bg.jpg);
    background-position-x: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding-top: 160px;
    padding-bottom: 60px;

    .container-box {
        .box-content {
            padding-top: 130px;
        }

        .tabs {
            width: 100%;
            position: absolute;
            top: 40px;
            padding-right: 66px;

            .MuiTabs-flexContainer {
                align-items: center;
                justify-content: space-between;
                gap: 20px;
            }

            button {
                width: calc(50% - 10px);
                color: white;
                text-transform: none;
                font-size: 16px;
                border-radius: 8px;
                background: #40315A;

                &.Mui-selected {
                    background: #5D40E4;
                }
            }

            .MuiTabs-indicator {
                display: none;
            }
        }

        .reward {
            color: #FBFF39;
            font-size: 16px;
            text-align: right;
            margin: 10px 0;
        }

        .summary {
            padding-top: 20px;
            display: block;

            .summary-item {
                margin: 0 30px;
                padding: 0 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: none;
                transition: 0.3s all ease-in-out;
                height: 0px;
                overflow: hidden;

                &.h-100 {
                    height: 40px;
                    border-bottom: 1px dashed #4c3e75;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .liquidity-box {
        .tabs {
            top: 18px;

            button {
                font-size: 14px;
            }
        }

        .summary-item {
            margin: 0 !important;
            margin-bottom: 10px !important;
        }
    }
}