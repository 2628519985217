.tab-container {
    .tab-item {
        background: #fff;
        color: #000;
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal !important;
        cursor: pointer;
        transition: 0.2s all ease-in-out;
        user-select: none;
        padding: 5px 10px;
        border-radius: 15.877px;
        border: 1px solid #000;
        &.active {
            box-shadow: 0px 4px 4px 0px #000;
        }
    }
}

@media (max-width: 800px) {
    .tab-container {
        .tab-item {
            font-size: 14px !important;
            padding: 20px 0;

            &.active {
                box-shadow: 0px 4px 4px 0px #000;
            }
        }
    }
}