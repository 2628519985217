.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 0px 60px;
    .icon {
        width: 180px !important;
    }

}

.scrolled-header {
    background-color: #22222211 !important;
    backdrop-filter: blur(30px);

    .navbar-container {
        padding: 10px 30px !important;

        .icon {
            width: 120px !important;
        }

        .account-icon {
            width: 30px;
        }
    }
}

.navbar-container {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    transition: 0.3s all ease-in-out;

    // .icon {
    //     transition: 0.3s all ease-in-out;
    //     width: 180px;
    // }

    .icon{
        color: #000;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Inter;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        
    }

    .nav-link-box {
        display: flex;
        align-items: center;

        .nav-link {
            color: #23262F;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            text-decoration: none;
            margin: 0 25px;
            padding: 0.5rem 0;
            position: relative;
            text-transform: uppercase;

            &:hover {
                color: #23262F;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: #23262F;
                transform: scaleX(0);
                transform-origin: left;
                transition: transform 0.3s ease-in-out;
            }

            &:hover::after {
                transform: scaleX(1);
            }
        }
    }

    .account-icon {
        width: 20px;
        // margin-right: 10px;
        transition: 0.3s all ease-in-out;
    }

    .address {
        color: #23262F;
        font-size: 9px;
        font-weight: 500;
    }

    .copy-icon {
        color: #A693FE;
        cursor: pointer;
        transition: 0.2s all ease-in-out;

        &:hover {
            color: #23262F;
        }
    }

    .btn-disconnect {
        font-size: 16px;
        font-weight: 800;
        background: linear-gradient(270deg, #D196FF 0%, #7B90FE 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: pointer;
        transition: 0.2s all ease-in-out;
        user-select: none;

        &:hover {
            text-shadow: 0 0 10px #7B90FE;
        }
    }

    .hamburger-react {
        color: #23262F;
    }

    .menu-icon {
        display: none;
    }
}

.mobile-menu {
    .MuiList-root .MuiMenuItem-root {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
    }

    .connect-btn {
        background-color: #3A42FC !important;
        min-height: 40px;
    }
}

@media (max-width: 800px) {
    // .icon {
    //     width: 100px !important;
    //     margin-left: 15px;
    // }

    .icon {
        font-size: 32px;
    }

    .navbar-container {
        padding: 10px 0;

        .nav-link-box {
            scale: 0.6;

            .menu-icon {
                display: block !important;
            }

            .list-items {
                display: none;
            }
        }
    }
}