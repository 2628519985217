.history-container {
    width: 100%;
    max-width: 600px;
    padding: 0 20px;
    margin: 0 auto;
    margin-top: 50px;
    overflow-y: auto;

    .title {
        color: #FFF;
        font-size: 20px;
        margin-bottom: 15px;
        text-align: center;
    }

    .grid-box {
        color: #CEC3FF;
        text-align: center;

        .token {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }

        img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
        }
    }

}