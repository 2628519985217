.footer-bg {
    width: 100% !important;
    height: 700px;
    // background-image: url(../../assets/images/home-banner.jpg);
    background-position-x: center;
    background-position-y: -140px;
    background-repeat: no-repeat;
    padding-top: 20px;
}

@media (max-width: 800px) {
    .footer-bg {
        height: 230px;
        background-size: 700px;
        background-position-y: -47px;
    }
}

.footer {
    .title{
        color: #23262F;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 125% */
        text-transform: capitalize;
    }
    .content {
        color: #4C5266;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 200% */
    }
}