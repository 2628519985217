.swap-box {
    width: 100% !important;
    background-image: url(../../assets/images/swap_bg_1.png);
    background-position-x: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding-top: 160px;
    padding-bottom: 60px;
    padding-left: 60px !important;
    padding-right: 60px !important;
    .switcher-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        // border-bottom: 1px solid rgba(65, 98, 183, 0.5);
        padding-bottom: 12px;
        position: relative;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 5px;
        }
    }

    .switcher {
        position: absolute;
        left: 50%;
        top: calc(50%);
        transform: translate(-50%, -50%);
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;

        // &:hover {
        //     background: #352775;
        // }
    }

    /* Styling for the AccordionSummary */
    .MuiAccordionSummary-root {
        color: white;
        background: transparent;
        display: flex;
        align-items: center; // Center vertically
        justify-content: center; // Center horizontally
        padding: 8px 16px; // Adjust padding as needed
    }

    .summary-text {
        color:black;
        flex-grow: 1; // Allow "Summary" text to grow to fill available space
        text-align: center; // Center the text
        font-weight: 500;
        width: 150px;
    }

    .expand-icon {
        margin-left: auto; // Add margin between the text and the icon
    }

    .white-separator {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dashed rgba(128, 128, 128, 0.5);
        margin-bottom: 8px;
        padding-bottom: 8px;
        font-weight: 500;
        span {
            display: inline-block;
            width: 50%;
            text-align: right;
            &:first-child {
                flex-grow: 1;
            }
        }
        span:first-child {
            margin-right: auto;
        }

        span:last-child {
            margin-left: auto;
        }
    }

    .swap-box .MuiAccordionDetails-root {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}
.swap-desc{
    background: rgba(232, 244, 252, 0.30);
    
    .desc-box{
        width: 215px;
        height: 157px;
        border-radius: 36px;
        background: var(--colors-alias-black-white-white, #FFF);
        box-shadow: 0px 64px 64px 20px rgba(0, 0, 0, 0.03);
        .label {
            color: var(--Neutrals-2, #23262F);
            text-align: center;
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px; /* 130% */
        }
    }
    .title {
        color: var(--Neutrals-2, #23262F);
        text-align: center;
        font-family: Poppins;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 68px; /* 141.667% */
    }
    .link-box {
        display: flex;
        width: fit-content;
        height: 74px;
        padding: 21.984px 5px;
        justify-content: center;
        align-items: center;
        gap: 9.77px;
        flex-shrink: 0;
        border-radius: 14.656px;
        background: #000;
        .label {
            display: inline;
            color: var(--colors-alias-black-white-white, #FFF);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Montserrat;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 20.898px; /* 52.245% */
            letter-spacing: -0.251px;
        }
    }
}
.text-red {
    color: red;
}

@media (max-width: 768px) {
    .swap-box {
        width: 100% !important;
        padding-top: 80px;
    }

    .swap-box .coming-text {
        text-align: center;
        font-size: 30px;
    }

    .swap-box .soon-text {
        text-align: center;
        font-size: 30px;
        margin-bottom: 35px;
    }

    .swap-box .content {
        display: block !important;
    }
}
