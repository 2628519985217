.projects-box {
    width: 100% !important;
    background-image: url(../../assets/images/swap_bg_1.png);
    background-position-x: center;
    background-repeat: no-repeat;
    padding-top: 110px;
    padding-left: 50px;
    padding-right: 50px;
    .content-panel{
        border-radius: 14.656px;
        background: var(--colors-alias-black-white-white, #FFF);
        box-shadow: 0px 4.885px 4.885px 0px rgba(0, 0, 0, 0.25);
        padding: 20px 40px;
        .title-box {
            color: #000;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Montserrat;
            .title{
                font-size: 50px;
                font-style: normal;
                font-weight: 700;
                line-height: 40px; 
                letter-spacing: -0.251px;
            }
            .sub-title{
                font-size: 22px;
                font-style: normal;
                font-weight: 600;
                letter-spacing: -0.251px;
            }
        }
        .body-box.intro {
            margin-top: 40px;
 
            // .desc-item{   
            //     width: 370px;
            //     border-radius: 36px;
            //     padding:10px 32px;
            //     background: var(--colors-alias-black-white-white, #FFF);
            //     box-shadow: 0px 64px 64px 20px rgba(0, 0, 0, 0.03);                  
            //     .title{
            //         font-family: Poppins;  
            //         color: var(--Neutrals-2, #23262F);
            //         text-align: center;
            //         font-size: 22px;
            //         font-weight: 600;
            //     }
            //     .desc{
            //         color: #4C5266;
            //         font-family: Inter;
            //         font-size: 16px;
            //         font-weight: 400;
            //     }
            // }
        }
        .desc-item{   
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: fit-content;
            width: 370px;
            border-radius: 36px;
            padding:10px 32px;
            background: var(--colors-alias-black-white-white, #FFF);
            box-shadow: 0px 4.885px 4.885px 0px rgba(0, 0, 0, 0.23);                  
            .title{
                font-family: Poppins;  
                color: var(--Neutrals-2, #23262F);
                text-align: center;
                font-size: 22px;
                font-weight: 600;
            }
            .desc{
                color: #4C5266;
                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
            }
        }
        .btn-label{
            border-radius: 15.877px;
            background: #030303;   
            display: flex;
            padding: 14.656px 10px;
            flex-direction: column;
            align-items: center;
            gap: 24.426px;
            flex-shrink: 0;
            color: var(--colors-alias-black-white-white, #FFF);
            text-align: center;
            font-family: Poppins;
            font-size: 45px;
            font-style: normal;
            font-weight: 600;
            line-height: 40px; /* 53.333% */
        }
    }

    .banner-text {
        display: none !important;
    }

    .explore-title {
        margin-top: 100px;
    }

    .presale-launchpad {
        margin-top: 150px;
        margin-bottom: 50px;
    }
    
    .presale-description{
        color: white;
        width: 750px;
        font-size: 30px;
        margin-bottom: 20px;
        text-align: center;
        line-height: 60px;
    }

    .tabs-explore {
        // margin-top: 160px;
    }

    .social {
        margin-top: 300px !important;
        margin-bottom: 50px;
    }

    .links .social-icon {
        padding: 0 30px;

        img {
            width: 45px !important;
        }
    }
    
    .accordion-container {
        background-color: transparent;
        margin-bottom: 20px;
        box-shadow: none;
        margin-top: 40px;

        .MuiAccordionSummary-root {
            background: #201629;
            color: #AC9DC8;
            font-size: 20px;
            border-radius: 12px;
            padding: 0 30px;
            min-height: 80px;
        }

        .MuiAccordionDetails-root {
            color: #9A93A7;
            font-size: 18px;
            line-height: 1.5;
        }
    }
}



@media (max-width: 800px) {
    .projects-box {
        width: 100vw !important;
        background-size: auto 420px;
        padding-top: 80px;

        .explore-title {
            margin-top: 50px;
        }

        .presale-launchpad {
            margin-top: 50px;
            margin-bottom: 50px;
        }
        

        .tabs-explore {
            margin-top: 10px;
        }

        .MuiPagination-root {
            justify-content: center;
            margin-top: 40px;
        }

        .MuiButtonBase-root.MuiPaginationItem-root {
            min-width: 30px;
            min-height: 30px;
            font-size: 12px;
            border-radius: 10px;
            margin: 0 2px;
        }

        .social {
            margin-top: 150px !important;
            margin-bottom: 30px;
        }

        .links .social-icon {
            padding: 0 10px;

            img {
                width: 25px !important;
            }
        }

        .presale-description{
            color: white;
            width: 400px;
            font-size: 16px;
            margin-bottom: 20px;
            text-align: center;
            line-height: 25px;
        }
    
    }
}