.selectcard-container {
    width: 140px;
    color: white;
    // padding: 13px 21px;
    border-radius: 8px;

    cursor: pointer;
    user-select: none;
    margin-bottom: 10px;

    &:hover {
        background: #352775;
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
    }

    .label {
        font-size: 14px;
        padding-bottom: 10px;
    }

    .token-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #252B36  ;
        padding: 1px 5px;
        height: fit-content;
        .token {
            display: flex;
            align-items: center;
            padding-right: 0px;
            height: 40px;

            .token-icon {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                overflow: hidden;
            }

            .symbol {
                font-size: 14px;
                font-weight: 700;
            }
        }
    }
}