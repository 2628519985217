.table-container {
    // margin-top: 30px;
    // margin-bottom: 60px;
    max-width: 100%;
    overflow: auto;

    .link-icon {
        height: 40px;
    }

    .MuiButtonBase-root {
        background-color: #a3ff12 !important;
        color: black !important;
        font-weight: 600 !important;
    }

    table {
        // min-width: 1000px;
        width: 100%;

        th {
            color: #8A73AC;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
        }


        td {
            height: 40px;
            color: white;

            .tx {
                display: flex;
                align-items: center;
            }

            .link-icon {
                height: 30px;
                padding-top: 5px;
            }
        }

        tbody {
            font-weight: 400;
            font-size: 14px;
        }

        .tx {
            img {
                width: 20px;
            }

            a {
                color: white;
                opacity: 0.5;
                padding-left: 5px;
            }
        }

        .green {
            color: #a3ff12;
        }
    }
}